import React from 'react'
import './services.scss'
import { BiCheck } from 'react-icons/bi'

const Services = () => {
  return (
    <section id='services'>
      <h5>What I offer</h5>
      <h2>Services</h2>

      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>UI/UX Design</h3>
          </div>
          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>Gather and evaluate user requirements in collaboration with product managers and engineers.
              </p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Design graphic user interface elements, like menus, tabs and widgets.
              </p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Build page navigation buttons and search fields.
              </p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Identify and troubleshoot UX problems (e.g. responsiveness).
              </p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Adhere to style standards on fonts, colors and images.
              </p>
            </li>
          </ul>
        </article>
        {/* END OF UI/UX */}
        <article className="service">
          <div className="service__head">
            <h3>Web Development</h3>
          </div>
          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>Write well designed, testable, efficient code by using best software development practices.
              </p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Create website layout/user interface by using standard HTML/CSS/JS/React practices
              </p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Integrate data from various back-end services and databases.
              </p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Gather and refine specifications and requirements based on technical needs.
              </p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Cooperate with web designers to match visual design intent.
              </p>
            </li>
          </ul>
        </article>
        {/* END OF WEB DEVELOPMENT */}
      </div>
    </section>
  )
}

export default Services